import React from 'react'


const Scroll = () => {
  return (
    <div className='scroll__down'>
        <a href="#contact">Scroll Down</a>
    </div>
  )
}

export default Scroll